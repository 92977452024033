

export default function Row(props) {
    return (
        <div className={'relative w-full rounded-large flex flex-col items-center overflow-hidde ' + (props.noPadding ? '' : 'p-8 sm:p-12 lg:p-16 ') + (props.className || '')} id={props.id}>

            {(props.image || props.noise) && <>
                {props.image && <div className='w-full h-full absolute top-0 left-0 bg-cover bg-fixed opacity-50 mix-blend-multiply grayscale ' style={{ backgroundImage: 'url(' + props.image + ')' }} />}
                <div className='w-full h-full absolute top-0 left-0 bg-repeat bg-fixed opacity-[0.035]' style={{ backgroundImage: 'url(/assets/images/noise.png)' }} />
            </>}

            {props.fullWidth ? props.children : <div className={'w-full max-w-boxed flex flex-col gap-16 z-10 ' + (props.contentClassName || '')}>{props.children}</div>}

        </div>
    );
}