
import { TfiLocationPin, TfiEmail, TfiMobile } from 'react-icons/tfi';

import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function ContactRow(props) {
    return (
        <Row className='bg-white' id={props.id}>

            <div className='w-full max-w-boxed flex flex-col gap-12 lg:gap-16'>

                <RowTitle prefixClassName='text-cyan-800' className='text-neutral-800' title='Ajánlatkérés' subTitle='Amennyiben termékünk felkeltette érdeklődését, kérjük a lenti űrlap segítségével lépjen velünk kapcsolatba. Néhány napon belül minden megkeresésre válaszolunk!' />

                <div className='flex gap-6 items-stretch'>

                    <div className='flex flex-col gap-6 w-1/3'>
                        <Input label='Név' />
                        <Input label='Cégnév' />
                        <Input label='E-mail cím' />
                        <Input label='Telefonszám' />
                    </div>

                    <div className='flex-1 flex flex-col gap-6'>
                        <Input className='flex-1' inputClassName='h-full' label='Üzenet' area />
                        <button className='w-full button button-primary'>Üzenet küldése</button>
                    </div>

                    {/* <button className='button button-white'>Üzenet küldése</button> */}

                </div>

            </div>

        </Row >
    );
}

function Input(props) {
    return (
        <div className={'relative w-full ' + (props.className || '')}>
            {/* <div className='absolute h-full top-0 left-0 bg-white rounded-lg text-neutral-800 px-4 text-base flex items-center justify-center font-bold font-condensed' >{props.label}</div> */}
            {props.area ? <textarea placeholder={props.label} className={'generic-input-dark w-full ' + (props.inputClassName || '')} /> : <input placeholder={props.label} className={'generic-input-dark w-full ' + (props.inputClassName || '')} />}
        </div>
    );
}

function DataItem(props) {
    return (
        <div className='flex flex-row text-xs'>
            <div className=''>{props.label + ':'}&nbsp;</div>
            <div className='font-light'>{props.value}</div>
        </div>
    );
}

function ContactItem(props) {
    return (
        <div className='flex flex-col items-end'>

            <div className='flex items-center gap-2'>

                {props.icon && <div className='text-lg'>{props.icon}</div>}
                <div className='text-xl'> {props.title}</div>

            </div>

            <a className='text-base hover:text-cyan-600' href={props.href}>{props.value}</a>

        </div>
    );
}