
import { useState, useEffect } from 'react';

import { FaChevronDown } from "react-icons/fa";

import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function FaqRow(props) {
  const itemList = [
    {
      'question': 'Hogyan működik a ködhűtés',
      'answer': 'A hűtési technológia egy egyszerű fizikai törvényszerűségen a párolgás okozta hőelvonáson alapul. A víz egy speciális páraköd képző fúvókán keresztül jut a levegőbe, ahol az apró vízcseppek egy része azonnal elpárolog, így akár több fokkal hűvösebb levegő képződik a környezetükben.',
    },
    {
      'question': 'Mennyire hatásos a páraköd',
      'answer': 'Nagy meleg és alacsony páratartalom esetén a leghatékonyabb a párahűtés. Kellemes, felüdülést keltő hatását alacsony páratartalom mellett és 30 Celsius fok fölötti melegben képes leginkább kifejteni.',
    },
    {
      'question': 'Miért érdemes a kültési hűtéstechnikát alkalmazni',
      'answer': 'A nyári hónapokban hűvösebbé, elviselhetőbbé teszi környezetét; leköti a port, polleneket és egyéb allergén anyagokat; csökkenti a repülő rovarok jelenlétét; környezetbarát technológia; működése áram felhasználást nem igényel; tisztítja, frissíti a levegőt, így az allergiások számára is előnyösebb; számtalan helyen és környezetben alkalmazható, ahol a légkondicionálók nem',
    },
    {
      'question': 'Hová ajánljuk a pára, vagy más néven ködhűtést',
      'answer': 'Számtalan lehetőség kínálkozik felhasználására, ahol a magas léghőmérséklet, vagy a páratartalom alacsony szintje problémát okoz. Elsősorban szabadtéri rendezvények, események közérzet javító eszköze lehet, de az állattenyésztés, kertészet, borászat, vagy az ipar egyes területein is betölthet funkcionális feladatokat.',
    },
    {
      'question': 'Nedvesek lesznek-e a "Frissítő sziget" közelében lévő emberek',
      'answer': 'A speciális szórófejekből, hálózati nyomáson kiáramló vízcseppek egy része azonnal elpárolog, míg a másik része a levegőben lebegve lassan leereszkedik. Magas 35 C fok fölötti hőmérséklet esetén ezek az apró cseppek sem érik el a talajt. Nedvesítő hatása 1-2 m-es közelségből érzékelhető.',
    },
    {
      'question': 'Tartalmaz-e a természetre káros anyagot a ködhűtés',
      'answer': 'A hálózati víznyomásról működő hűvös köd rendszerek környezetbarát és energiatakarékos technológiát képviselnek. Működésükhöz a vízhálózathoz való csatlakozási lehetőség szükséges. Használatuk során egyéb energia felhasználást nem igényelnek.',
    },
    // {
    //   'question': 'Bonyolult a hűvös köd rendszer összeszerelése',
    //   'answer': 'Rendszereink úgy lettek kialakítva, hogy a legjobb hatásfokkal lássák el feladatukat. Egyszerű, mindenki által könnyen elsajátítható legyen a telepítésük, kezelésük, de minden körülmények között biztonságosak és megbízhatóak legyenek.',
    // },
    {
      'question': 'Mennyire hangos',
      'answer': 'A rendszer mozgó alkatrészeket nem tartalmaz, igy használata nem lehet zavaró.',
    },
    {
      'question': 'A hűvös köd rendszer kezelése igényel-e szaktudást',
      'answer': 'Rendszereink, a közüzemi vízhálózathoz csatlakoztatva azonnal működőképesek, anélkül hogy szükségük lenne bármilyen más energiaforrásra. Telepítésük, kezelésük egyszerű, mindenki által könnyen elsajátítható a termékhez mellékelt tájékoztató füzet elolvasásával, vagy a telepítő szakemberünk beüzemelési bemutatója alkalmával.',
    },
  ];

  return (
    <Row className='bg-cyan-800 text-white' image='/assets/images/faq-bg.jpg' id={props.id}>

      <RowTitle title='Gyakran ismételt kérdések' subTitle='Az alábbi pontokban igyekeztünk megválaszolni a leggyakrabban felmerülő kérdéseket. Kérjük, amennyiben további információra lenne szüksége termékeinkkel, vagy rendszerünk működésével kapcsolatban, keresse ügyfélszolgálatunkat elérhetőségeinken.' />

      <div className='flex flex-col gap-4 p-4 bg-white bg-opacity-5 rounded-large backdrop-blur shadow-xl'>
        {itemList.map((item, index) => (<Item key={index} data={item} />))}
      </div>

    </Row>
  );
}

function Item(props) {
  const [open, setOpen] = useState(false);

  return (
    <div className='flex flex-col bg-white bg-opacity-20 rounded-small'>

      <button className='flex items-center rounded-small bg-cyan-800 hover:bg-white text-left overflow-hidden p-4 transition-colors text-white hover:text-cyan-800 shadow-xl ' onClick={() => setOpen(!open)}>

        <div className='flex-1 font-bold tracking-wide uppercase transition-colors '>{props.data.question}</div>
        <FaChevronDown className={'font-bold text-2xl opacity-80 transition ' + (open ? 'rotate-180' : 'rotate-0')} />

      </button>

      {open && <div className='text-white text-sm p-4'>{props.data.answer}</div>}

    </div>
  );
}