
import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function IntroRow(props) {
  const itemList = [
    {
      'title': 'Valami újat keres a nyár elviselhetőbbé tételére?',
      'value': 'Sokféleképpen hűsíthetjük magunkat a kánikulában, azonban az egyik legsikeresebb megoldás a párahűtés, vagy más néven párakapu.',
      'image': '/assets/images/intro/1-ujat-keres.jpg'
    },
    {
      'title': 'Van készleten a párakapu?',
      'value': 'Igen nagy raktárkészlettel rendelkezünk, így bármikor rendelhető. A készülékek túlméretesek ezért csomagküldő szolgálattal nem szállíthatók.',
      'image': '/assets/images/intro/4-keszlet.jpg'
    },
    {
      'title': 'Valami hatékony megoldás után kutat?',
      'value': 'Hosszú évek tapasztalatait figyelembe véve fejlesztjük alacsony nyomású ködhűtő, vagy ismertebb nevén párakapu készülékeinket, hogy megbízható és energiatakarékos terméket tudjunk kínálni felhasználóink számára.',
      'image': '/assets/images/intro/2-hatekony.jpg'
    },
    {
      'title': 'Végre olyan párakaput szeretne vásárolni, ami tartós minőséget képvisel?',
      'value': 'A Natural Cooling Innovation Kft. által fejlesztett és az Inoxmester Kft. által kivitelezett párásító ívek, ismertebb nevükön párakapuk ,kiváló minőséget képviselnek, amire a fejlesztő hosszú évek alatt gyűjtött tapasztalata és a kivitelező szakmai felkészültsége a garancia. Minden általunk forgalmazott párakapu, inox (rozsdamentes) fém szerkezetből és ipari minőséget képviselő fúvókákból és tartozékokból kerül összeszerelésre, hogy problémamentes, hosszú távú használatot tegyen lehetővé minden felhasználási körülmény között.',
      'image': '/assets/images/intro/3-minoseg.jpg'
    },

    {
      'title': 'Ez az eredeti párakapu (párásító ív)?',
      'value': 'Évek óta azon tevékenykedünk,hogy a legjobb minőségű terméket kínáljuk felhasználóink számára. Biztosítunk minden vásárlási szándékú érdeklődőt, hogy az általunk fejlesztett párakapu, eredeti, hamisítatlan és saját termék. Számunkra fontos, hogy minőségi párásító íveket tudjunk kínálni vásárlóink felé. Párakapuink minőségében biztosan nem fog csalódni. Garantáljuk, hogy a tőlünk megszokott kiváló terméket gyártjuk le és adjuk át megrendelés esetén.Vigyázz hamisítják!',
      'image': '/assets/images/intro/5-eredeti.jpg'
    },
    {
      'title': 'Az általunk fejlesztett és gyártott párakapu sikerének titka!',
      'value': 'Minden termék annyira sikeres, amennyire a fejlesztő együtt tud gondolkodni a felhasználó igényével. Mi azon fejlesztők és gyártók közé tartozunk, akik rendszeresen monitorozzák a felhasználóktól begyűjtött információkat, majd azok figyelembevételével hajtjuk végre a kívánatosnak tartott változtatásokat és fejlesztéseket.',
      'image': '/assets/images/intro/6-siker.jpg'
    },
    {
      'title': 'Miért jó választás az alacsony nyomású párakapu?',
      'value': 'Talán sokan nem tudják, de létezik magas nyomású páraképző is. Ezek a készülékek finomabb vízcseppeket képesek előállítani az alacsony nyomású készülékekhez képest. Azonban arról senki nem beszél, hogy a finomabb páracseppek előállításához sokkal bonyolultabb készülékekre és kiegészítőkre van szükség, ami nagyságrendekkel megnöveli a telepítési költséget a hálózati víznyomáson működő párakapukhoz képest. Arról nem is beszélve, hogy magas fenntartási, karbantartási és szervizelési költségei vannak, míg a hálózati víznyomáson működő párakapuk a víz használatán kívül, egyéb energia felhasználást nem igényelnek. Természetesen vannak helyzetek, ahol csak a magasnyomású készülék alkalmazható, azonban, ahol mindkét fajta készülék alkalmas a feladat ellátására, ott egyértelműen az alacsony nyomású párakapuk használata az ajánlott.',
      'image': '/assets/images/intro/7-jo-valasztas.jpg'
    },
  ]

  return (
    <Row className='h-128 bg-cyan-800 text-white' image='/assets/images/intro-bg.jpg' id={props.id}>

      <RowTitle titleClassName='text-white' title='Kezdje itt' subTitle='Fő tevékenységünk a hálózati víznyomáson működő, kültéri párásító, ködhűtő készülékek (párakapu, frissítő pont) fejlesztése, gyártása. Célunk: széles felhasználói körben elérhetővé tenni a környezetbarát és energiatakarékos, ipari minőséget képviselő „párakapuk” használatát.' />

      <div className='flex flex-col gap-10 lg:gap-24 pb-4'>
        {itemList.map((item, index) => (<Item key={index} data={item} even={index % 2 === 0} />))}
      </div>

    </Row>
  );
}

function Item(props) {
  return (
    <div className={'flex flex-col lg:flex-row bg-white bg-opacity-25 rounded-large shadow-xl ' + (props.even ? 'ml-0 lg:ml-16 xl:ml-36' : 'mr-0 lg:mr-16 xl:mr-36')}>

      <div className={'relative overflow-hidden flex p-5 aspect-video rounded-large shadow-xl w-full lg:w-64 even:order-2 lg:-my-4 ' + (props.even ? 'lg:order-1' : 'lg:order-2')}>
        <div className='absolute top-0 left-0 w-full h-full bg-cover bg-center grayscale-[.5] rounded-large' style={{ backgroundImage: 'url(' + props.data.image + ')' }} />
        <div className='absolute top-0 left-0 w-full h-full bg-cyan-800/20 mix-blend-multiply ' />
      </div>

      <div className={'text-white flex-1 flex flex-col gap-2 rounded-r-large p-4 lg:p-6 ' + (props.even ? 'lg:order-2 lg:text-left' : 'lg:order-1 lg:text-right')}>

        <div className='text-2xl font-condensed font-semibold'>{props.data.title}</div>
        <p className='w-full text-sm'>{props.data.value}</p>

      </div>

    </div>
  );
}