

export default function RowLister(props) {
    return (
        <div className={'w-full flex flex-col site-gap ' + (props.className || '')}>
            
            {props.children}

        </div>
    );
}