import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';

import RowLister from 'elements/RowLister';

import HeroRow from 'rows/HeroRow';
import IntroRow from 'rows/IntroRow';
import ServicesRow from 'rows/ServicesRow';
import ProductRow from 'rows/ProductRow';
import OfferRow from 'rows/OfferRow';
import FaqRow from 'rows/FaqRow';
import ContactRow from 'rows/ContactRow';

export default function MainPage(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        updatePosition();
    }, [pathname]);

    const scrollConfig = { duration: 800, delay: 0, offset: window.innerWidth > 768 ? -128 : -100, smooth: 'easeInOutQuart', isDynamic: true };

    const updatePosition = () => {
        if (pathname === '/kezdje-itt') { scroller.scrollTo('kezdje-itt', scrollConfig); }
        else if (pathname === '/szolgaltatasok') { scroller.scrollTo('szolgaltatasok', scrollConfig); }
        else if (pathname === '/termekek') { scroller.scrollTo('termekek', scrollConfig); }
        else if (pathname === '/ajanlatkeres') { scroller.scrollTo('ajanlatkeres', scrollConfig); }
        else if (pathname === '/gyik') { scroller.scrollTo('gyik', scrollConfig); }
        else if (pathname === '/kapcsolat') { scroller.scrollTo('kapcsolat', scrollConfig); }
        else { scroller.scrollTo('hero', scrollConfig); }
    };

    return (
        <RowLister className='gap-0 md:gap-0'>

            <HeroRow id='hero' />

            <RowLister className='site-padding pt-0 md:pt-0'>
                <IntroRow id='kezdje-itt' />
                <ServicesRow id='szolgaltatasok' />
                <ProductRow id='termekek' />
                <OfferRow id='ajanlatkeres' />
                <FaqRow id='gyik' />
                <ContactRow id='kapcsolat' />
            </RowLister>

        </RowLister>
    );
}