
import { useEffect } from 'react';
import Row from 'elements/Row';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function HeroRow(props) {

    useEffect(() => {
        document.getElementById('video-background').play();
    }, []);



    return (
        <div className='w-full min-h-screen site-padding flex flex-col' id={props.id}>

            <Row className='relative bg-cyan-800 w-full flex-1 flex items-center justify-center p-0 sm:p-0 lg:p-0' fullWidth noPadding noise>

                <div className='relative flex-1 flex overflow-hidden w-full rounded-large'>

                    <video autoplay loop muted playsinline id="video-background" className="absolute top-0 z-10 w-full h-full grayscalee object-cover">
                        <source src="/assets/images/buborek.mp4" type="video/mp4" />
                        <source src="/assets/images/buborek.webm" type='video/webm; codecs="vp8, vorbis"' />
                        Your browser does not support the video tag.
                    </video>

                    <div className='bg-cyan-800 absolute mix-blend-multiply opacity-100 z-10 w-full h-full' />

                </div>

                <div className='absolute top-0 w-full h-full z-20 flex items-center justify-center p-16'>

                    <motion.div
                        className='relative flex flex-col gap-8 items-center text-center text-white mt-16 bg-white bg-opacity-10 backdrop-blur rounded-large shadow-xl p-8 lg:p-16 lg:pb-20'
                        initial={{ scale: 0.2, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: 0.5, type: 'spring', stiffness: 50 }}>

                        <div className='flex flex-col gap-2'>
                            <div className='font-extrabold uppercase text-4xl md:text-6xl'>Ipari párakapuk gyártása</div>
                            <div className='text-xl xl:text-4xl font-thin'>Tartós, és biztonságos használatra kialakítva</div>
                        </div>

                        <motion.div className='h-1.5 w-64 bg-cyan-800'
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 1.5, type: 'spring', stiffness: 100 }} />
                        {/* <motion.div className='h-1.5 w-64 bg-cyan-800' /> */}

                        <div className='flex flex-col gap-2 font-condensed text-lg xl:text-3xl font-thin'>
                            <div className='text-2xl'>Amennyiben erre a termékre van szüksége, jó helyen jár.</div>
                            <div className=''>Kérje díjmentes, személyre szabott ajánlatunkat párakapu készítésére.</div>
                        </div>

                        <div className='lg:absolute bottom-0 lg:h-0 flex flex-col lg:flex-row gap-2 lg:gap-4 items-center'>

                            {/* <div className='rounded bg-white text-neutral-800 uppercase font-bold tracking-wide py-3 px-5 text-lg font-condensed'>Termékleírás</div>
                            <div className='rounded bg-cyan-800 uppercase font-bold tracking-wide py-3 px-5 text-lg font-condensed'>Ajánlatkérés</div> */}

                            <motion.div className='flex'
                                initial={{ scale: 0.5, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ delay: 2, type: 'spring', stiffness: 100 }} >
                                <Link className='button button-white' to='/termekek'>Termékleírás</Link>
                            </motion.div>

                            <motion.div className='flex'
                                initial={{ scale: 0.5, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ delay: 2.5, type: 'spring', stiffness: 100 }} >
                                <Link className='button button-primary' to='/ajanlatkeres'>Ajánlatkérés</Link>
                            </motion.div>




                        </div>

                    </motion.div>

                </div>

            </Row>

        </div>
    );
}