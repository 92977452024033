import { TfiSettings, TfiShoppingCartFull, TfiComments, TfiTime } from 'react-icons/tfi';

import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function ServicesRow(props) {
  return (
    <Row className='bg-neutral-800 text-white' contentClassName='lg:gap-24' image='/assets/images/test.jpg' id={props.id}>

      <RowTitle prefixClassName='text-cyan-800' className='self-center' title='Szolgáltatásaink' subTitle='A Natural Cooling Innovation Kft. az alábbi területeken tud Önnek segítséget nyújtani' />

      <div className='hidden lg:flex w-full px-12 items-center justify-center'>

        <div className='relative w-full max-w-[810px] aspect-[810/294] bg-none lg:bg-cover mb-56' style={{ backgroundImage: 'url(/assets/images/services-main.png)' }}>
          <ItemList />
        </div>

      </div>

      <div className='grid lg:hidden grid-cols-1 sm:grid-cols-2 gap-8'>
        <ItemList />
      </div>

    </Row>
  );
}

function ItemList(props) {
  return (
    <>
      <Item className='-top-4 -left-12' icon={<TfiSettings />} label='Gyártás' value='Saját fejlesztésű termékeket gyártunk, de ha Ön egy koncepcióval, vagy egy végleges egyedi tervel rendelkezik, szakértőink együtt tudnak dolgozni Önnel, hogy a megfelelő egyedi megoldást tudjuk szállítani.' />
      <Item className='bottom-5 -left-12' icon={<TfiShoppingCartFull />} label='Forgalmazás' value='Alacsony nyomású párásító íveink már 18 éve ismertek az ország minden pontján. A lakossági és ipari felhasználásra egyaránt alkalmas párakapuink folyamatos fejlesztéseken és ellenőrzéseken esnek át az egyszerű használhatóság és a minőségi mutatók javítása érdekében.' />
      <Item className='-top-4 right-52' right icon={<TfiTime />} label='Bérbeadás' value='Bérleti lehetőséget ajánlunk rendezvények, egyéb események lebonyolításához, vagy szezonális jellegű felhasználáshoz.' />
      <Item className='bottom-5 right-52' right icon={<TfiComments />} label='Konzultáció' value='Szakmai tapasztalatunkra támaszkodva segítjük ügyfeleink tájékozódását és döntését, hogy termékeink közül a legmegfelelőbbet sikerüljön kiválasztani, vagy egyedi elképzeléseik megvalósításában is tudunk támogatást adni.' />
    </>
  );
}

function Item(props) {
  return (
    <div className={'lg:absolute lg:w-0 lg:h-0 ' + (props.right ? 'lg:text-right ' : '') + (props.className || '')}>

      <div className={'flex flex-col gap-2 w-full lg:w-64 ' + (props.contentClassName || '')}>

        <div className={'flex lg:flex-col gap-3 lg:gap-4 ' + (props.right ? 'lg:items-end' : '')}>
          <div className='text-4xl'>{props.icon}</div>
          <div className='text-xl font-condensed font-semibold uppercase tracking-wide'>{props.label}</div>
        </div>

        <div className='text-sm'>{props.value}</div>

      </div>

    </div>
  );
}