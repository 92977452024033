
import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function ProductRow(props) {
  const itemList = [
    {
      'title': 'Valami újat keres a nyár elviselhetőbbé tételére?',
      'value': 'Sokféleképpen hűsíthetjük magunkat a kánikulában, azonban az egyik legsikeresebb megoldás a párahűtés, vagy más néven párakapu.',
    },
  ]

  return (
    <Row className='h-128 bg-cyan-800 text-white' image='/assets/images/intro-bg.jpg' id={props.id}>

      <RowTitle titleClassName='' title='Termékeink' subTitle='Frissítő pontjaink kialakításának egyik legmarkánsabb képviselője az NCI Eco F név alatt jegyzett párásító ív, vagy ismertebb nevén párakapu, amely látványos megjelenése mellett, valódi frissítő élményt nyújt a tikkasztó nyári melegben. Kialakításánál elsődleges cél volt a könnyed szerelhetőség, a nagyfokú biztonság és az intenzív páraköd képző hatás, amit öt szórófej alkalmazásával valósítottunk meg. Szerkezeti felépítése, fix telepítésre lett optimalizálva, hogy ezzel is segítsük a kivitelezők munkáját és a felhasználók általi gondtalan hosszú távú működtetést. A hosszú élettartamot a rozsdamentes fém szerkezet és az ipari minőséget képviselő fúvókák alkalmazása biztosítja. A párásító ív, szórófejeinek elhelyezkedése következtében egy függőleges párafüggönyt hoz létre, ami 2-3 méter mélységben képes hűteni a levegő hőfokát.' />

      <div className='flex flex-col gap-8'>

        <div className='flex flex-col md:flex-row gap-8'>
          <Item image='/assets/images/product/parakapu_kreativ_3.jpg' className='aspect-[9/16] flex-1' />
          <Item image='/assets/images/product/parakapu_kreativ_1.jpg' className='aspect-[9/16] flex-1' />
          <Item image='/assets/images/product/parakapu_para_3.jpg' className='aspect-[9/16] flex-1' />
        </div>

        <div className='flex flex-col md:flex-row items-stretch gap-8'>

          <div className='flex-1'>
            <video autoplay="{true}" loop muted className="flex-1 aspect-[9/16] object-cover rounded-large">
              <source src="/assets/images/product/elonezet_360.mp4" type="video/mp4" />Your browser does not support the video tag.
            </video>
          </div>

          <div className='flex flex-col gap-8 flex-1'>
            <Item image='/assets/images/product/parakapu_para_2.jpg' className='flex-1 aspect-[16/9] md:aspect-auto' />
            <Item image='/assets/images/product/parakapu_9.jpg' className='flex-1 aspect-[16/9] md:aspect-auto'  />
            <Item image='/assets/images/product/parakapu_10.jpg' className='flex-1 aspect-[16/9] md:aspect-auto'  />
          </div>
          
        </div>

      </div>

    </Row>
  );
}

function Item(props) {
  return (
    <div className={'bg-cover bg-center bg-no-repeat rounded-large overflow-hidden shadow-xl ' + (props.className || '')} style={{ backgroundImage: 'url(' + props.image + ')' }}>

    </div>
  );
}