import { useState, useEffect } from 'react';
import { TfiLocationPin, TfiEmail, TfiMobile, TfiWorld } from 'react-icons/tfi';

import Row from 'elements/Row';
import RowTitle from 'elements/RowTitle';

export default function ContactRow(props) {
    return (
        <Row className='bg-neutral-800 text-white' image='/assets/images/contact-bg.jpg' id={props.id}>

            <div className='w-full max-w-boxed flex flex-col gap-10 md:gap-16'>

                <RowTitle prefixClassName='text-cyan-800' title='Kapcsolat' subTitle='Szívesen állunk rendelkezésére a megfelelő egységek összeállításában, az alkalmazási területek szerinti szerelési módok kiválasztásában. Amennyiben kérdése van, vagy egyedi árajánlatot igényelne, kérjük, vegye fel velünk a kapcsolatot az alábbi elérhetőségeinken, vagy küldjön üzenetet az űrlap kitöltésével.' />

                <div className='flex flex-col md:flex-row gap-10 md:gap-16'>

                    <div className='flex flex-col gap-8 min-w-max'>

                        <div className='flex flex-col gap-4 md:text-right'>

                            <div className='text-2xl font-condensed font-semibold'>Natural Cooling Innovation<br />Kereskedelmi és Szolgáltató Kft.</div>

                            <div className='flex flex-col gap-1 md:items-end'>
                                <DataItem label='Cégjegyzékszám' value='19-09-518202' />
                                <DataItem label='Adószám' value='25447785-2-19' />
                                <DataItem label='Székhely' value='8500 Pápa, Szegfű u. 6/4' />
                            </div>

                        </div>

                        <div className='grid grid-cols-2 md:grid-cols-1 gap-6'>
                            <ContactItem title='Cím' value='8500 Pápa, Harmat u. 3.' href='' icon={<TfiLocationPin />} />
                            <ContactItem title='Web' value='ipariparakapu.hu' href='https://ipariparakapu.hu/' icon={<TfiWorld />} />
                            <ContactItem title='E-mail' value='kapcsolat@naturalcooling.eu' href='mailto:kapcsolat@naturalcooling.eu' icon={<TfiEmail />} />
                            <ContactItem title='Telefon' value='+36 20 366 7176' href='tel:+36 20 366 7176' icon={<TfiMobile />} />
                        </div>

                    </div>

                    <div className='flex-1 flex flex-col'>

                        <div className='flex flex-col gap-6'>

                            <Input label='Név' />
                            <div className='flex flex-col sm:flex-row gap-6'>
                                <Input label='E-mail cím' />
                                <Input label='Telefonszám' />
                            </div>
                            <Input label='Üzenet' area />

                            <button className='button button-white'>Üzenet küldése</button>

                        </div>

                    </div>

                </div>

            </div>

        </Row >
    );
}

function Input(props) {
    return (
        <div className='relative w-full'>
            {/* <div className='absolute h-full top-0 left-0 bg-white rounded-lg text-neutral-800 px-4 text-base flex items-center justify-center font-bold font-condensed' >{props.label}</div> */}
            {props.area ? <textarea placeholder={props.label} className='generic-input w-full h-56' /> : <input placeholder={props.label} className='generic-input w-full' />}
        </div>
    );
}

function DataItem(props) {
    return (
        <div className='flex flex-row text-xs'>
            <div className=''>{props.label + ':'}&nbsp;</div>
            <div className='font-light'>{props.value}</div>
        </div>
    );
}

function ContactItem(props) {
    return (
        <div className='flex flex-col md:items-end gap-1 md:gap-'>

            <div className='flex items-center gap-2'>

                {props.icon && <div className='text-lg'>{props.icon}</div>}
                <div className='ótext-xl'> {props.title}</div>

            </div>

            <a className='text-xs sm:text-base hover:text-cyan-600' href={props.href}>{props.value}</a>

        </div>
    );
}