import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function MenuSlider(props) {
    return (
        <motion.div
            className={'fixed w-full h-full flex flex-col ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-black bg-opacity-30'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <motion.div
                className='w-full max-h-full bg-white bg-opacity-80 rounded-b-3xl shadow-xl flex flex-col overflow-hidden py-4 px-4'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                transition={{ ease: 'easeInOut' }}
                variants={{ visible: { y: 0, display: 'flex' }, hidden: { y: '-100%', transitionEnd: { display: 'none' } } }}>

                <div className='pt-20'>
                    <MenuList />
                </div>

            </motion.div>

        </motion.div >
    );
}

function MenuList(props) {
    return (
        <div className={'rounded-xl flex flex-col py-4 overflow-hidden bg-neutral-800 bg-opacity-95 shadow-lg ' + (props.className || '')}>

            <div className={'flex flex-col px-4 ' + (props.flex ? 'overflow-y-scroll line-scrollbar' : '')}>

                <MenuItem label='Kezdje itt' path='kezdje-itt' />
                <MenuItem label='Szolgáltatások' path='szolgaltatasok' />
                <MenuItem label='Termékek' path='termekek' />       
                <MenuItem label='Ajánlatkérés' path='ajanlatkeres' />
                {/* <MenuItem label='Rólunk' path='rolunk' /> */}
                <MenuItem label='GYIK' path='gyik' />
                <MenuItem label='Kapcsolat' path='kapcsolat' higlight />

            </div>

        </div>
    );
}

function MenuItem(props) {
    return (
        <Link to={props.path} className={'h-full tracking-wide py-4 px-2 xl:px-4 rounded leading-none flex items-center justify-center uppercase font-semibold text-white ' + (props.higlight ? 'bg-cyan-800 ' : '')}>
            {props.label}
        </Link>
    );
}