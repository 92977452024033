
import { useState, useEffect } from 'react';
import { Spin as Hamburger } from 'hamburger-react'
import { Link } from 'react-router-dom';

import MenuSlider from 'components/MenuSlider.js';

export default function Header(props) {
    const [scroll, setScroll] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const onScroll = () => { setScroll(window.pageYOffset); };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <header className={'w-full site-top flex justify-end site-padding fixed ' + (props.className || '')}>

                <div className='w-full h-18 md:h-20 bg-white site-margin-x rounded-lg p-2 flex items-center justify-between shadow-2xl'>

                    <Link to='/' className='flex flex-row items-center h-full gap-3 lg:gap-4 group'>

                        <div className='rounded bg-cyan-800 group-hover:bg-neutral-800 transition-colors h-full aspect-square p-2'>
                            <img className='h-full' alt='logo' src='/assets/images/logo-white.svg' />
                        </div>

                        {/* <div className='uppercase'>
                            <div className='font-black tracking-wider text-2xl'>Natural Cooling <span className='font-thin tracking-normal'>Innovation Kft.</span></div>
                            <div className='text-lg font-thin capitalize'>Hűvös zóna - Forró nyári napokra</div>
                        </div> */}

                        <div className='md:hidden lg:flex flex-col gap-1 whitespace-nowrap'>
                            <div className='uppercase font-black text-xl tracking-wide leading-none'>Natural Cooling</div>
                            <div className='uppercase tracking-wide leading-none'>Innovation Kft.</div>
                        </div>

                    </Link>

                    <div className='hidden md:flex h-full p-2'>

                        <MenuItem label='Kezdje itt' path='kezdje-itt' />
                        <MenuItem label='Szolgáltatások' path='szolgaltatasok' />
                        <MenuItem label='Termékek' path='termekek' />
                        <MenuItem label='Ajánlatkérés' path='ajanlatkeres' />
                        {/* <MenuItem label='Rólunk' path='rolunk' /> */}
                        <MenuItem label='GYIK' path='gyik' />
                        <MenuItem label='Kapcsolat' path='kapcsolat' higlight />

                    </div>

                    <div className='flex items-center justify-center md:hidden h-full aspect-square rounded bg-neutral-800'>
                        <Hamburger size={30} color='#ffffff' direction='left' rounded toggled={menuOpen} toggle={() => setMenuOpen(!menuOpen)} />
                    </div>

                </div>

            </header>

            <div className='fixed top-0 flex md:hidden z-40'>
                <MenuSlider open={menuOpen} onSliderClose={() => setMenuOpen(false)} />
            </div>
        </>
    );
}

function MenuItem(props) {
    return (
        <Link to={props.path} className={'h-full tracking-wide py-3 px-2 xl:px-4 rounded leading-none flex items-center justify-center font-condensed uppercase font-semibold transition-colors ' + (props.higlight ? 'text-white bg-cyan-800 hover:bg-neutral-800 ml-2' : 'hover:text-cyan-800')}>
            {props.label}
        </Link>
    );
}