

export default function Row(props) {
    const titleClassName = 'uppercase text-3xl sm:text-5xl lg:text-6xl font-black';
    const subTitleClassName = 'text-sm sm:text-base font-light';

    return (
        <div className={'flex items-start gap-2 ' + (props.className || '')}>

            <div className={titleClassName + ' ' + (props.titleClassName || '') + ' ' + (props.prefixClassName || '')}>_</div>

            <div className='flex flex-col gap-2 sm:gap-4'>
                {props.title && <div className={titleClassName + ' ' + (props.titleClassName || '')}>{props.title}</div>}
                {props.subTitle && <div className={subTitleClassName + ' ' + (props.subTitleClassName || '')}>{props.subTitle}</div>}
            </div>

        </div>
    );
}