import Header from 'components/Header';

import MainPage from 'pages/MainPage';
import CookieConsent from 'elements/CookieConsent';


export default function Client(props) {
    return (

        <div className='relative w-full flex flex-col min-h-screen'>

            <Header className='z-50' />

            <div className='flex flex-col'>

                <MainPage />

            </div>

            <CookieConsent className='z-50' />

        </div>
    );
}

